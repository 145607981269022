import React from 'react';

import { Link } from 'gatsby';

const NotFound = () =>{
    return(
        <>
            <div className="p-404">
                <div className="p-404-box">

                    <p className="p-404-box--title u-josef">
                    404 PAGE NOT FOUND.
                    </p>
                    <p className="p-404-box--text">
                    お探しのページが見つかりませんでした。<br />
                    お手数ですが、ホームへ移動するかメニューから再度アクセスしてください。
                    </p>

                    <div className="c-link">
                        <Link to="/" title="ホーム" className="c-link--btn">
                        HOME<span className="c-link--cursor"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;


